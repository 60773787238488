/* eslint-disable no-return-assign */
import SearchFilter from '../../auto/stores/SearchFilter';

(() => {
  // Adicione chaves:valor com os stores que serão atribuídos ao escopo
  // global
  const store = {
    SearchFilter,
  };
  window.store = Object.assign(window.store || {}, {});
  Object.keys(store).forEach(c => (window.store[c] = store[c]()));
})();
